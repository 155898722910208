import Timer from "./components/Countdown/Timer";
import Optin from "./components/Optin/Optin";
import Preloader from "./components/Preloader/Preloader";
import "./styles.css";
function App() {
  return (
    <div className="App">
      <div className="container">
        <h1>
          {" "}
          Disqover
          <br />
          Coming Soon
        </h1>
        <Timer />
        <Optin />
        <Preloader />
        <div className="footer">&copy; 2021, Alessandro & Nico Chalimba</div>
      </div>
    </div>
  );
}

export default App;
