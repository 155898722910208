const firebaseConfig = {
  apiKey: "AIzaSyAQXcv-kYP9SpHIXPO0UXsNOwURCVXfU10",
  authDomain: "project-investor-e951d.firebaseapp.com",
  projectId: "project-investor-e951d",
  storageBucket: "project-investor-e951d.appspot.com",
  messagingSenderId: "730557657463",
  appId: "1:730557657463:web:9a1429413fdae5182c3172",
  measurementId: "G-23Q6PR9VKF",
};

export { firebaseConfig };
