// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { addDoc, collection, getFirestore } from "firebase/firestore/lite";
import { firebaseConfig } from "../secrets";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);
const db = getFirestore(firebase);

const addDocument = (collectionName, document) => {
  try {
    const docRef = addDoc(collection(db, collectionName), document);
    return docRef;
  } catch (e) {
    console.error("error adding email: ", e);
  }
};

export { firebase, db, addDocument };
