import { Formik } from "formik";
import React, { useState } from "react";
import { addDocument } from "../Firestore";
import "./Optin.css";

export default function Optin() {
  const [success, setSuccess] = useState(false);
  const saveMail = (values) => {
    const email = values.email;
    addDocument("contacts", { email: email });

    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 5000);
  };
  return (
    <div>
      <div className="optin">
        <Formik
          initialValues={{ email: "" }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            saveMail(values);
            resetForm({});
            setSubmitting(false);
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <h3>
                We will launch soon! Enter your email address and get our early
                notification.
              </h3>
              <input
                type="email"
                placeholder="Email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />

              {
                <div className="errorMessage">
                  {errors.email && touched.email && errors.email}
                </div>
              }

              <button type="submit" disabled={isSubmitting}>
                Submit
              </button>
            </form>
          )}
        </Formik>

        {success && <p>Successfully submitted!</p>}
      </div>
    </div>
  );
}
